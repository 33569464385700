import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import languageContext from "../context/language/languageContext";

const Alert = () => {
    const navigate = useNavigate();
    const {alertPrompt,message,setAlert} = useContext(languageContext)
    const Close = () => {
        localStorage.setItem('login','false')
        setAlert(false);
        setTimeout(() => {
            navigate("/");
        }, 500);
    };

    return (
        <>
                {alertPrompt&&<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-4 text-red-500">Alert</h2>
                        <p>{message}</p>
                        <div className="mt-6 flex justify-end">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded-lg"
                                onClick={Close}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>}
        </>
    );
};

export default Alert
