import { useNavigate } from "react-router-dom"
import { useState } from "react";

const HistoryComp = (props) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(prevState => !prevState);
  }

  return (
    <div className="bg-slate-200 w-full mx-2 my-2 rounded-md px-2 py-1" >
      <div className=" font-bold">{
        props.heading && typeof props.heading === 'string' ?
          isExpanded ? props.heading : props.heading.length > 200 ? `${props.heading.slice(0, 200)}...` : props.heading : (
            "" // Fallback for when heading is not available
          )}
        {props.heading && typeof props.heading === 'string' ? props.heading.length > 200 && (
          <button className='bg-bluebtn rounded-lg px-8 py-2 my-4 text-white font-[100] w-full text-center cursor-pointer' onClick={handleToggle}>
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        ) : <span>No User Input available</span>}
      </div>
      <div>{props.desc && typeof props.desc === 'string' ? isExpanded ? props.desc : props.desc.length > 300 ? `${props.desc.slice(0, 300)}...` : props.desc : (
        "" // Fallback for when heading is not available
      )}
        {props.desc && typeof props.desc === 'string' ? props.desc.length > 300 && (
          <button className='bg-bluebtn rounded-lg px-8 py-2 my-4 text-white font-[100] w-full text-center cursor-pointer' onClick={handleToggle}>
            {isExpanded ? 'Read Less' : 'Read More'}
          </button>
        ) : <span>No Bot response available</span>}
      </div>
    </div>
  )
}
export default HistoryComp