import Navbar from "../components/Navbar"

const Checklist = ()=>{
    return(
        <>
        <Navbar/>
        <div>Hi checklist</div>
        </>
    )
}
export default Checklist