import { FaArrowLeft } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RiLogoutCircleRLine } from "react-icons/ri";
import languageContext from "../context/language/languageContext";
import { useContext } from "react";

const Navbar = () => {
    const { setChats } = useContext(languageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.state?.url || '/'; // Retrieve URL from state with fallback

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        navigate("/");
    }

    return (
        <div className="bg-bgcolor w-full h-[80px] flex items-center justify-between">
            <div className='flex'>
                {/* Back button with saved state */}
                <Link to={url}>
                    <div className='w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center ml-5'>
                        <FaArrowLeft />
                    </div>
                </Link>
            </div>
            
            <div className='flex'>
                {/* Conditional rendering: Only show if the URL contains '/ask' */}
                {location.pathname.includes('/ask') && (
                    <>
                        {/* Adding state for /booking */}
                        <Link 
                            to="/booking" 
                            state={{ url: location.pathname }} // Save current pathname in state
                        >
                            <div className='bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer'>
                                Book My Next Appointment
                            </div>
                        </Link>

                        {/* Adding state for /checklist */}
                        <Link 
                            to="/checklist" 
                            state={{ url: location.pathname }} // Save current pathname in state
                        >
                            <div className='bg-slate-200 ml-2 rounded-lg my-auto px-2 py-2 cursor-pointer'>
                                Checklist for Next Appointment
                            </div>
                        </Link>
                    </>
                )}
                <div className='w-[40px] h-[40px] bg-white rounded-full flex justify-center items-center mx-5' onClick={logout}>
                    <RiLogoutCircleRLine size='1.2rem' />
                </div>
            </div>
        </div>
    );
}

export default Navbar;
