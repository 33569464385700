import Navbar from "../components/Navbar"

const Booking = ()=>{
    return(
        <>
        <Navbar/>
        <div>Hi this is booking </div>
        </>
    )
}
export default Booking