import HistoryComp from "./History";
import languageContext from "../context/language/languageContext";
import { useContext, useEffect } from "react";
import axios from 'axios';

const PrevQues = () => {
    // Accessing chats from languageContext
    const { chats, setChats, dataApi, setAlert, setMessage } = useContext(languageContext);
    const { lang, setSessionTimeout } = useContext(languageContext);
    
    useEffect(() => {  
        gettingResponse();
    }, [dataApi]);

    const gettingResponse = async () => {
        try {
            let headersList = {
                "Accept": "*/*",
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            };
            let response = await axios.post(
                "https://www.careplus.gopillz.com/chat_history",
                null,
                { headers: headersList }
            );
            setChats(response.data.chat_history);
        } catch (error) {
            if (error.response && error.response.data.detail === 'Token has expired') {
                setSessionTimeout(true);
            } else {
                console.error("Error Details:", error.message);
                setAlert(true);
                setMessage('Please try again later, something went wrong');
            }
        }
    };

    return (
        <div className="relative flex justify-between items-stretch h-full overflow-x-hidden">
            <div className="w-11/12 relative">
            
            <div className="py-1">
                {/* Symptom Tracker fixed at the top */}
                {/* Chat history */}
                
                
                {chats && chats.map((chat, index) => (
                    <HistoryComp
                        key={index}
                        heading={chat.user_question} 
                        desc={chat.bot_response}
                    />
                ))}  
                   
            </div>
            </div>
        </div>
    );
};

export default PrevQues;
